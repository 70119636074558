import classNames from 'classnames';
import NextLink, { LinkProps } from 'next/link';
import React from 'react';

interface Props extends LinkProps {
  className?: string;
  children?: React.ReactNode;
}

export const Link: React.FC<Props> = (props) => {
  let { children, className, ...nextLinkProps } = props;

  return (
    <NextLink className={classNames('underline', className)} {...nextLinkProps}>
      {children}
    </NextLink>
  );
};
