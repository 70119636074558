import { GetStaticPropsContext, GetStaticPropsResult } from 'next';
import React from 'react';

import { Link } from '../components/atoms/link';
import { PageTemplate } from '../components/templates/page-template';
import { DocsManifestType, getDocsManifest } from '../utils/docs';

interface Props {
  language: string;
  docsManifest: DocsManifestType;
  title?: string;
}

const IndexPage: React.FC<Props> = (props) => {
  let { docsManifest } = props;

  return (
    <PageTemplate title="Viresmo Platform Documentation">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {docsManifest.map((c) => {
          return (
            <article className="block rounded p-4 border" key={c.slug}>
              <h2 className="text-lg font-bold mb-2 text-blue-600">{c.title}</h2>

              <ul className="list-inside">
                {c.docs.map((d) => {
                  let asLink = `/docs/${c.slug}/${d.slug}`;

                  return (
                    <li className="mb-2" key={asLink}>
                      <Link href="/docs/[...slug]" as={asLink}>
                        {d.title}
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </article>
          );
        })}
      </div>
    </PageTemplate>
  );
};

export function getStaticProps(context: GetStaticPropsContext): GetStaticPropsResult<Props> {
  let locale: string = context.locale || context.defaultLocale;
  let docsManifest = getDocsManifest(locale);

  return {
    props: {
      language: locale,
      docsManifest,
    },
  };
}

export default IndexPage;
