import Head from 'next/head';
import React from 'react';

export type Props = {
  title?: string;
  description?: string;
};

export const SEO: React.FC<Props> = (props) => {
  let { title = '', description = '' } = props;

  return (
    <Head>
      <link rel="icon" type="image/x-icon" href="/favicon.ico"></link>
      <meta name="viewport" content="width=device-width,minimum-scale=1,initial-scale=1"></meta>

      <title>{title}</title>
      <meta name="title" content={title} />
      <meta name="description" content={description} />

      {/* Facebook */}
      <meta property="og:type" content="website" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />

      {/* Twitter */}
      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:title" content={title} />
      <meta property="twitter:description" content={description} />
    </Head>
  );
};
