import { useRouter } from 'next/router';
import React from 'react';

import { Link } from '../atoms/link';
import { SEO } from '../organisms/seo';

interface Props {
  title: string;
  children?: React.ReactNode;
}

export const PageTemplate: React.FC<Props> = (props) => {
  let { children, title } = props;
  let { locales, pathname, asPath, locale } = useRouter();

  return (
    <div className="mx-auto max-w-screen-xl p-8">
      <SEO title={title} />
      <div className="md:flex justify-between">
        <div>
          <Link href="/" className="text-2xl font-bold mb-8 text-gray-600">
            Viresmo Documentation
          </Link>
        </div>
        <ul className="flex print:hidden">
          {locales.map((l) => {
            return (
              <li className="uppercase mr-4 md:mr-0 md:ml-4 text-lg" key={l}>
                {locale === l ? (
                  <span className="font-bold">{locale}</span>
                ) : (
                  <Link className="font-medium" href={pathname} as={asPath} locale={l}>
                    {l}
                  </Link>
                )}
              </li>
            );
          })}
        </ul>
      </div>
      <div className="text-2xl font-bold my-8 text-blue-600 text-center">{title}</div>
      <div>{children}</div>
    </div>
  );
};
